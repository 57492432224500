
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import React from "react";
import Router from "next/router";

import { PageSeo } from "seo-modules/PageSeo";

import ErrorComponent from "components/ErrorComponent/ErrorComponent";
import basicPageFetcher from "modules/basic-pages/basicPageFetcher";
import I18nProvider from "next-translate/I18nProvider";
import { i18Internals } from "settings/global-website-config";
import setNamespaces from "modules/setNamespaces";
import { logError, clearSessionStorage } from "modules/utils";

function Custom404(props) {
  const { lang } = props; // NB THIS WAS JUST lang - CHANGED ASSUMING REQD FOR V10

  const supportedNamespaces = ["common", "errors", "nav"];
  const namespaces = setNamespaces(lang, supportedNamespaces);

  const { seo } = props;

  if (process.browser) {
    const path = Router.asPath;
    // in case we'd added transitionProps but there was an error during the reroute

    clearSessionStorage();

    // check if there's any info in the URL that we can log
    if (path !== "/404") {
      // send the path to an api which collects failed paths
      let failedQuery;

      if (path.indexOf("#") !== -1) {
        // We passed a failed url in the router query, so:
        failedQuery = Router.asPath.split("#")[1];
      } else {
        failedQuery = path;
      }
      const referrer = document?.referrer;

      logError(failedQuery, referrer || "404Page (no referrer found)", 404);
    }
  }

  return (
    <>
      <I18nProvider
        lang={lang}
        namespaces={namespaces}
        internals={i18Internals}
      >
        {seo && <PageSeo seo={seo} pageTitle="404" />}
        <ErrorComponent />
      </I18nProvider>
    </>
  );
}

Custom404.fullScreen = true;

 async function _getStaticProps() {
  const uri = "error";
  console.log("[Build] | Fetching basic page to build 404 page.");

  return basicPageFetcher(uri);
}

export default Custom404;


    export async function getStaticProps(ctx) {
        let res = _getStaticProps(ctx)
        if(typeof res.then === 'function') res = await res
        return {
          
          ...res,
          props: {
            ...(res.props || {}),
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/404',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  